import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from 'config/firebase';
import { doc, runTransaction, serverTimestamp, setDoc } from 'firebase/firestore';
import { useAuthGlobalContext } from 'context/AuthContext';

export default function Register() {

    const { dispatch } = useAuthGlobalContext();
    const [state, setState] = useState({ name: "", email: "", password: "", });
    const [isProcessing, setisProcessing] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, password } = state;

        if (name.length <= "") { return window.notify("Please enter name", "error") }
        if (email.length <= "") { return window.notify("Please enter email", "error") }
        if (password.length <= "") { return window.notify("Please enter password", "error") }
        if (password.length < 8) { return window.notify("Enter Your Password / Minimum Length 8", "error") }

        setisProcessing(true)
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                await setDoc(doc(firestore, "users", user.uid), {
                    name: name,
                    email: email,
                    uid: user.uid,
                    roles: ["user"],
                    status: "active",
                    dateCreated: serverTimestamp(),

                })
                setState({ name: "", email: "", password: "", })
                setisProcessing(false)
                registeredUser()
                window.notify("A new user has been successfull registed", "success")
                dispatch({ type: "LOGIN", payload: { user } })
            })
            .catch((error) => {

                if (error.code === 'auth/invalid-email') {
                    window.notify('Invalid email', "error");
                }
                if (error.code === 'auth/email-already-in-use') {
                    window.notify('This email-already-in-use', "error");
                }
                setisProcessing(false)
            });

    }

    const registeredUser = async () => {

        const docRef = doc(firestore, "transactions", "users");
        try {
            await runTransaction(firestore, async (transaction) => {
                const sfDoc = await transaction.get(docRef);
                const newPopulation = sfDoc.data().users + 1;
                transaction.update(docRef, { users: newPopulation });

            });
        } catch (e) {
            console.log(e)
            window.notify("Something went wrong while generating user number", "error")
        }
    }

    // const provider = new GoogleAuthProvider();
    // const handlerGoogle = (e) => {
    //     e.preventDefault();
    //     signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             GoogleAuthProvider.credentialFromResult(result);
    //             const user = result.user;

    //             window.notify("Successfullfy login", "success")

    //             await setDoc(doc(firestore, "users", user.uid), {
    //                 photoURL: user.photoURL,
    //                 fullName: user.displayName,
    //                 uid: user.uid,
    //                 roles: ["user"],
    //                 status: "active",
    //                 dateCreated: serverTimestamp(),

    //             }, { merge: true }).then(() => {
    //                 console.log("document Create")
    //             }).catch((error) => {
    //                 console.log(error)
    //             })

    //             dispatch({ type: "LOGIN" })

    //         }).catch((error) => {
    //             GoogleAuthProvider.credentialFromError(error);
    //             console.log(error)
    //         });
    // }

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <nav >
                <div className='container py-3 navbar'>
                    <Link to="/"> <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" /> </Link>
                </div>
            </nav>
            <form>
                <div className='login-form'>
                    <div className="container min-vh-100 d-flex justify-content-center flex-column">
                        <div className="row d-flex justify-content-center ">
                            <div className="col-12 col-md-5">
                                <div className="card rounded-4 border-0 shadow-lg w-100 " data-aos="zoom-in">
                                    <div className="row">
                                        <div className="col p-3">
                                            <div className="card-body ">
                                                <div className="row">
                                                    <div className="col">
                                                        <h3 className=''>Register</h3>
                                                        <p className="text-muted">Enter details to create your account </p>
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter your name"
                                                                aria-label="First name"
                                                                name="name"
                                                                value={state.name}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-4">
                                                        <div className="col">
                                                            <input type="email"
                                                                className="form-control"
                                                                placeholder="Enter your email"
                                                                aria-label="First name"
                                                                name="email"
                                                                value={state.email}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row ">
                                                        <div className="col">
                                                            <input type="password"
                                                                className="form-control"
                                                                placeholder="Enter your password"
                                                                aria-label="First name"
                                                                name='password'
                                                                value={state.password}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between underInput'>
                                                    <p>
                                                        Already Registered?
                                                        <Link to="/auth/login" className='fw-bold text-decoration-none text-primary forgot' > Login</Link>
                                                    </p>

                                                </div>
                                                <div className='text-center mb-4 loginDiv'>

                                                    <button className='btn btn-primary w-100 rounded-2 border-0 loginbutton text-white '
                                                        onClick={handleSubmit}
                                                        disabled={isProcessing}

                                                    >
                                                        {
                                                            !isProcessing ?
                                                                ("Register")
                                                                :
                                                                (
                                                                    <span className="spinner-border spinner-border-sm " role="status"></span>
                                                                )
                                                        }

                                                    </button>
                                                </div>

                                                <div className='login-or'>
                                                    <span className="or-line"></span>
                                                    <span className="span-or">Or</span>
                                                </div>

                                                <div className='d-flex justify-content-center'>
                                                    {/* <button
                                                        className='btn btn-sm border-0 text-white '
                                                        style={{ backgroundColor: "#dd4b39" }}

                                                        onClick={handlerGoogle}
                                                    >
                                                        <FaGoogle className='text-white mx-2' /></button> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
