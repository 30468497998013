import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Course from './Course'
import Blogs from './Blogs'

export default function index() {
    return (
        <Routes>
            <Route index element={<Blogs />} />
            <Route path=':slug' element={<Course />} />
        </Routes>
    )
}
