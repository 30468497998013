import { Link } from "react-router-dom"
import { VideoCameraOutlined, AppstoreAddOutlined, DashboardOutlined, MessageOutlined, SettingOutlined, SoundFilled, PlusOutlined, ReadOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';

const root = "/dashboard/"
const getRandomId = () => Math.random().toString(36).slice(2)

export const items = [
    {
        key: getRandomId(),
        icon: <DashboardOutlined />,
        label: <Link to={`/dashboard`}>Dashboard</Link>,
    },

    {
        key: getRandomId(),
        icon: <AppstoreAddOutlined />,
        label: "Blogs",
        children: [
            {
                key: getRandomId(),
                icon: <PlusOutlined />,
                label: <Link to={`${root}blogs/add`}>Add Blog</Link>
            },
            {
                key: getRandomId(),
                icon: <ReadOutlined />,
                label: <Link to={`${root}blogs`}>All Blog</Link>
            },
        ]
    },
    {
        key: getRandomId(),
        icon: <SettingOutlined />,
        label: "Settings",
        children: [
            {
                key: getRandomId(),
                icon: <SettingOutlined />,
                label: <Link to={`${root}settings/account`}>Account</Link>
            },
            {
                key: getRandomId(),
                icon: <UserOutlined />,
                label: <Link to={`${root}settings/profile`}>Profile</Link>
            },
        ]
    },
    {
        key: getRandomId(),
        icon: <SettingOutlined />,
        label: "Website Settings",
        children: [
            {
                key: getRandomId(),
                icon: <UnorderedListOutlined />,
                label: <Link to={`${root}website-settings/carousel`}>Carousel</Link>
            },
            {
                key: getRandomId(),
                icon: <UnorderedListOutlined />,
                label: <Link to={`${root}website-settings/faqs`}>FAQs</Link>
            },
            {
                key: getRandomId(),
                icon: <SoundFilled />,
                label: <Link to={`${root}website-settings/mainAd`}>Main Ad</Link>
            },
            {
                key: getRandomId(),
                icon: <VideoCameraOutlined />,
                label: <Link to={`${root}website-settings/videos`}>Videos</Link>
            },
        ]
    },
    {
        key: getRandomId(),
        icon: <MessageOutlined />,
        label: <Link to={'/dashboard/messages'}>Messages</Link>,
    },

]