import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Frontend from "./Frontend";
import Authentication from "./Authentication";
import { useAuthGlobalContext } from 'context/AuthContext';
import Dashboard from "../pages/Dashboard"
import PrivateRoute from 'Components/PrivateRoute';
export default function Routers() {
  const { isAuthenticated } = useAuthGlobalContext()

  const Wrapper = ({ children }) => {
    const location = useLocation()
    useEffect(() => {
      document.documentElement.scrollTo(0, 0)
    }, [location.pathname])
    return children
  }
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path='/*' element={<Frontend />} />
          <Route path='/auth/*' element={!isAuthenticated ? (<Authentication />) : (<Navigate to="/" replace />)} />
          <Route path='/dashboard/*' element={<PrivateRoute Component={Dashboard} allowedRoles={["superAdmin"]} />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  )
}
