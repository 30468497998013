
import React from 'react'

export default function ScreenLoader() {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" />
            </div>
        </>
    )
}
