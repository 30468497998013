import React, { useCallback, useEffect, useRef, useState } from 'react';
import { firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { BellOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons'
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import audioSound from "assets/audioplay.mp3"

export default function Header() {

    const { user, handlLogOut } = useAuthGlobalContext();
    const [messages, setMessages] = useState([]);
    const messageSound = useRef()

    const readMessages = useCallback(async () => {

        const collectionRef = query(collection(firestore, "messages"), where("isunRead", "==", true))
        const querySnapshot = await getDocs(collectionRef);

        let array = []
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let data = doc.data();
            data.key = data.id
            array.push(data)
        })
        setMessages(array)
    }, [])

    useEffect(() => {
        readMessages()
    }, [readMessages])

    const handleSound = async () => {

        // Check if there are any messages that need to play a sound
        const messagesToPlaySound = messages.filter(message => !message.sound);
        if (messagesToPlaySound.length === 0) {
            return; // No messages to play sound for
        }

        // Play sound and display notification for each message
        for (const message of messagesToPlaySound) {
            messageSound.current.play()
            message.sound = true;
            try {
                await setDoc(doc(firestore, "messages", message.id), message, { merge: true })
                toast.success(`You have a new message sent from ${message.name}`, { position: "bottom-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
            } catch (error) {
                message.error("Something went wrong. Please try again")
            }
        }
    }

    useEffect(() => {
        handleSound()
        // eslint-disable-next-line
    }, [messages])

    return (
        <>
            <div>
                <audio ref={messageSound} src={audioSound}></audio>
            </div>
            <header className='text-white position-sticky top-0 ' style={{ zIndex: 100 }}>
                <nav className="navbar navbar-expand-lg  navbar-dark" style={{ backgroundColor: "#002140", }}>
                    <div className="container-fluid">
                        <Link to="/"><img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" style={{ width: "200px" }} /> </Link>
                        <div className="d-flex">
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav me-auto">
                                    <li className="nav-item dropdown me-3">
                                        <button className="nav-link dropdown-toggle border-0 bg-transparent d-flex align-items-center p-0" data-bs-toggle="dropdown">
                                            <span className="text-white d-inline-block me-2 text-end">
                                                <small className="fw-bold">{user.fullName}</small>
                                                {/* <small>Super Admin</small> */}
                                            </span>
                                            {
                                                !user?.photo ?
                                                    <img src="https://res.cloudinary.com/dufkxmegs/image/upload/v1679915607/avatar_vxqxen.png" className='rounded-circle avatar' alt='Profile Pic' />
                                                    :
                                                    <img src={user.photo.url} alt="Profile Pic" className='rounded-circle' />
                                            }
                                        </button>
                                        <ul className="dropdown-menu pb-0">
                                            <li>

                                                <Link to="/dashboard/settings/profile" className="dropdown-item d-flex justify-content-center align-items-center "><UserOutlined className="me-2" /> My Profile</Link>

                                            </li>
                                            <li><Link to="/dashboard/settings/account" className="dropdown-item d-flex justify-content-center align-items-center "><SettingOutlined className="me-2" /> Account Settings</Link></li>
                                            <li><button className="btn btn-danger btn-sm w-100 text-white" onClick={() => handlLogOut()} >Logout</button></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><Link to="/dashboard/messages" className="nav-link">
                                        <Badge count={messages.length === 0 ? 0 : messages.length} color="#52c41a">
                                            <BellOutlined className="fs-5 text-white" />
                                        </Badge>
                                    </Link></li>
                                    <li className="nav-item"><Link to="/dashboard/notifications" className="nav-link"><i className="fa-regular fa-bell"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
