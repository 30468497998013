import { useState, useEffect, useCallback } from 'react';
import { firestore } from 'config/firebase';
import { collection, doc, query, setDoc, updateDoc, where, limit, getDocs } from 'firebase/firestore';
import { Breadcrumb, Button, message, Popconfirm, Table, Tag, Input, Space, Tooltip } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiSend } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const { Search } = Input;

export default function Messages() {

    const [searchText, setSearchText] = useState('');
    const [messages, setMessages] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handleSearch = value => {
        setSearchText(value);
    };

    const readDocs = useCallback(async () => {

        const collectionRef = collection(firestore, "messages");
        const statusActive = where("status", "==", "Active");
        const q = query(collectionRef, statusActive, limit(pageSize));

        setIsLoading(true);
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateCreated = doc.data().dateCreated;
            let date = null;
            if (dateCreated && dateCreated.seconds) {
                date = new Date(dateCreated.seconds * 1000 + dateCreated.nanoseconds / 1000000);
                data.date = date.toLocaleString();
            }
            data.key = doc.id;
            array.push(data);
        });
        array.sort((a, b) => b.dateCreated.seconds - a.dateCreated.seconds);
        setMessages(array);
        setFilteredDocuments(array);
        setIsLoading(false);

    }, [pageSize]);

    useEffect(() => {
        readDocs();
    }, [readDocs]);

    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setFilteredDocuments(messages.slice(start, end));
    }, [currentPage, pageSize, messages]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const SingleDocDelete = async (item) => {

        item.status = "delete"
        const docRef = doc(firestore, "messages", item.id)
        try {
            await setDoc(docRef, item, { merge: true })
            const activeMessage = messages.filter(message => message.id !== item.id)
            setMessages(activeMessage)
            message.success("Message successfully deleted")
        } catch (error) {
            message.error("Someting went wrong. Please try again")
        }

    }

    useEffect(() => {
        const filteredData = messages.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredDocuments(filteredData);
    }, [messages, searchText]);

    const handleReadDoc = async (id) => {
        const docRef = doc(firestore, "messages", id);
        try {
            await updateDoc(docRef, { isunRead: false })
        } catch (error) {
            console.log(error)
            window.toastify("Something went wrong.")
        }
    }

    const sendMessage = (data) => {
        const phoneNumber = data.whatsapp;  // replace with the team member's phone number
        // const message = 'Hi, I just added you to our team!/n/nHere are the details:%0A- Name: John Doe%0A- Position: Developer'; // replace with your message
        const message =
            `
 Hi ${data.name} 
 Thanks for contacting ETG
 
 How can I help you
 https://emergingtechgrid.com/
 `
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Whatsapp',
            dataIndex: 'whatsapp',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Select option',
            dataIndex: 'selectItem',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (tags) => (<> <Tag color="green">  {tags} </Tag> </>)
        },
        {
            title: 'Action',
            render: (item, row) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Send Message">
                            <Button style={{ backgroundColor: "#26d367", color: "white" }} size="small" type='primary' onClick={() => sendMessage(item)} ><FiSend /></Button>
                        </Tooltip>
                        <Popconfirm title="Sure to delete?"
                            okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => SingleDocDelete(item)}  >
                            <Button danger size="small" type='primary'><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ];

    const rowClassName = (record, index) => {
        if (record.isunRead) {
            return "rowColor"
        }
    };

    return (
        <div className='content-min-vh'>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/dashboard" className='text-decoration-none'>Dashboard</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Messages</Breadcrumb.Item>
            </Breadcrumb>
            <div className="py-4">
                <h5>Messages</h5>
            </div>
            <div className="bg-white p-4">
                <div className='py-4 d-flex justify-content-between align-items-center'>
                    <div className='d-flex '>
                        <p className='fw-bold pt-1 me-3'>Messages</p>
                        <Search placeholder='Search' onChange={e => handleSearch(e.target.value)} />
                    </div>
                    <div>
                        <Button type='primary' disabled >Delete</Button>
                    </div>
                </div>

                <Table loading={isLoading}
                    dataSource={filteredDocuments}
                    columns={columns}
                    pagination={{ current: currentPage, pageSize: pageSize, total: messages.length, showSizeChanger: true, }}
                    rowClassName={rowClassName}
                    onChange={handleTableChange}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div className='px-5'>
                                <h6>{record.subject} </h6>
                                <p>{record.message} </p>
                            </div>
                        ),
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                        onExpand: (expanded, record) => {
                            if (record.isunRead === false) {
                                return record;
                            } else {
                                return handleReadDoc(record.id);
                            }
                        }
                    }}
                    scroll={{ x: true }}
                    bordered
                />
            </div>
        </div>
    )
}
