import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
// import { FaShare } from 'react-icons/fa';
import { Button, Input, Modal, Space } from 'antd';
import Subscribe from 'pages/Frontend/Home/Subscribe';
// import ReactPlayer from 'react-player';

export default function Course() {
    const [course, setCourse] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const inputRef = useRef(null);

    const params = useParams()

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const readCourse = useCallback(async () => {

        setIsProcessing(true)
        const q = query(collection(firestore, "blogs"), where("slug", "==", params.slug));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((docs) => {
            let data = docs.data();
            let datecreated = docs.data().dateCreated;
            let date = new Date(datecreated.seconds * 1000 + datecreated.nanoseconds / 1000000);
            data.date = date.toLocaleString()
            setCourse(data)
        })
        setIsProcessing(false)

    }, [params])

    useEffect(() => {
        readCourse()
        // eslint-disable-next-line
    }, [readCourse])

    const handleCopy = () => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
            window.notify("URL successfullfy copied", "success")
            // Optionally, you can show a notification or perform any other action after copying.
        }
    };
    return (
        <div className='course'>
            <div className="course-details">
                {
                    !isProcessing ?
                        (
                            <>
                                <div className='container'>
                                    <div className='px-5'>
                                        <h1 className='text-primary mb-2'>{course.title}</h1>
                                        <div className='d-flex align-items-baseline mb-3'>
                                            <a href="https://dramaseries.fun/" className='me-2 fs-5' target="_blank" rel="noopener noreferrer">{course?.name}</a>
                                            <p>-- {course?.date}</p>
                                        </div>
                                        <div className="text-center mb-3">
                                            <img src={course?.photo?.url} className='img-fluid' alt="img" />
                                        </div>
                                        <div className="text-right customFont fs-4" style={{ direction: 'rtl', }}>
                                            <div dangerouslySetInnerHTML={{ __html: course.text }}></div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )
                        :
                        <>
                            <div className='py-5'>
                                <div className='d-flex justify-content-center py-5'>
                                    <span className='spinner-border spinner-lg text-primary'></span>
                                </div>
                            </div>
                        </>
                }
            </div>
            <Subscribe />

            <Modal title="Share this course" onOk={handleOk} centered className="custom-modal" open={isModalOpen} onCancel={handleCancel} footer={null} >
                <div className='py-3'>
                    <Space.Compact
                        style={{ width: '100%', }}
                    >
                        <Input defaultValue={window.location.pathname} readOnly ref={inputRef} />
                        <Button type="primary" onClick={handleCopy}>Copy</Button>
                    </Space.Compact>
                </div>
            </Modal>

        </div>
    )
}
