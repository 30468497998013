import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Modal } from 'antd';
import Home from "./Home";
import Footer from './Component/Footer';
import Navbar from './Component/Header';
import NotFound from './NotFound';
import Course from './Component/Course';

export default function Index() {

    const [mainAd, setMainAd] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        const updatedMainAdData = { ...mainAd, isNewAds: false };
        sessionStorage.setItem("mainAd", JSON.stringify(updatedMainAdData));
        setMainAd(updatedMainAdData);
        setIsModalOpen(false);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const mainAdData = JSON.parse(sessionStorage.getItem("mainAd"));
            if (mainAdData && mainAdData.isNewAds) {
                setMainAd(mainAdData);
                setIsModalOpen(true);
                clearInterval(intervalId);
            }
        }, 300);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {mainAd && isModalOpen &&
                (<Modal title="Main Ad" zIndex="1100" width={400} centered className="custom-modal" open={isModalOpen} onCancel={handleCancel} footer={null} maskClosable={false} >
                    <div>
                        <img src={mainAd?.photo?.url} className="img-fluid mb-3" style={{ width: "100%", height: "300px" }} alt="main ad" />
                        <h5 className='text-capitalize text-truncate text-primary'>{mainAd?.title}</h5>
                        <a href={`${mainAd?.url}`} className='btn btn-primary text-white w-100' target="_blank" rel="noopener noreferrer"> Subscribe</a>
                        {/* <button  onClick={handleApply}></button> */}
                    </div>
                </Modal>)}

            <Navbar />
            <main>
                <Routes>
                    <Route path='/'>
                        <Route index element={<Home />} />
                        <Route path='blog/*' element={<Course />} />
                        <Route path='*' element={<NotFound />} />
                    </Route>
                </Routes>
            </main>
            <Footer />
        </>
    )
}
