import { firestore } from 'config/firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import React, { useCallback, useState, useEffect } from 'react'
import ReactPlayer from 'react-player';

export default function Videos() {

    const [documents, setDocuments] = useState([]);
    const [videoURL, setVideoURL] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [videos, setVideos] = useState([])

    const readVideos = useCallback(async () => {

        setIsLoading(true)
        const collectionRef = query(collection(firestore, "videos"), where("status", "==", "active"), where("videoStatus", "==", "active"), orderBy("sortOrder", "asc"))
        const querySnapshot = await getDocs(collectionRef);

        let array = [];
        querySnapshot.forEach((docs) => {
            let data = docs.data()
            data.key = data.id
            array.push(data)
        })
        setDocuments(array)
        setIsLoading(false)

    }, [])

    useEffect(() => {
        readVideos()
    }, [readVideos])

    useEffect(() => {
        const defaultVideo = documents?.find((item) => {
            return item.sortOrder === 1;
        })
        setVideoURL(defaultVideo?.url)
    }, [documents])

    useEffect(() => {
        const videosData = documents?.filter((item) => {
            return item.sortOrder !== 1;
        })
        setVideos(videosData)
    }, [documents])



    const handleVideo = (data) => {
        setVideoURL(data.url)
    }

    return (
        <div className='video bg-dark py-5'>
            <div className='container py-5'>
                <div className='text-center text-white mb-5'>
                    <h1 className='text-primary'>Videos</h1>
                    <div className='underline'></div>
                </div>
                <div className="row">
                    <div className="col-lg-8" >
                        <div > <ReactPlayer url={videoURL} width="100%" height="420px" /></div>
                    </div>
                    <div className="col-lg-4">
                        <div className='videosList p-1 h-100'>
                            {
                                !isLoading ?
                                    videos?.map((item) => {
                                        return (
                                            <div key={item.id} className='d-flex mb-1' style={{ height: "75px", cursor: "pointer" }} onClick={() => handleVideo(item)} >
                                                <img src={item.photo?.url} className='me-1' style={{ maxWidth: "150px" }} alt="img" />
                                                <div className='content'>
                                                    <h6 className='text-primary heading'>{item.title}</h6>
                                                    <p className='text-white description'>{item.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    (
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                            <span className='spinner-border spinner-sm text-white'></span>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
