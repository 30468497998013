import React from 'react'

export default function Footer() {

    const year = new Date().getFullYear();

    return (

        <footer>
            <div className='bg-dark p-2'>
                <p className='p-0 m-0 text-white text-center'>  &copy; {year}.All Rights Reserved.</p>
            </div>
        </footer>
    )
}
