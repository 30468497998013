import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Input, message, Row, Select, Typography } from 'antd'
import { useDropzone } from 'react-dropzone';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography;

export default function EditVideo() {

    const { user } = useAuthGlobalContext()
    const [state, setState] = useState({});
    const [photo, setPhoto] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    let params = useParams();
    let navigate = useNavigate();

    const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } = useDropzone({
        accept: { 'image/*': [] }, multiple: false,
        onDrop: acceptedFiles => { setPhoto(acceptedFiles[0]) }
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleVideoStatus = (val) => {
        setState(s => ({ ...s, videoStatus: val }))
    }

    const readVideo = useCallback(async () => {

        const docRef = doc(firestore, "videos", params.id)
        setIsProcessing(true)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            window.toastify("Video not found", "error")
        }
        setIsProcessing(false)

    }, [params.id])

    useEffect(() => {
        readVideo()
    }, [readVideo])

    const handleUpdate = () => {
        let { title, url, sortOrder, videoStatus, description } = state;

        title = title.trim();
        url = url.trim();
        sortOrder = Number(sortOrder);
        description = description.trim();

        if (!title) { return message.error("Please enter video title") }
        if (!url) { return message.error("Please enter video url") }
        if (!videoStatus) { return message.error("Please enter select video status") }
        if (!sortOrder) { return message.error("Please enter sort order") }
        if (!description) { return message.error("Please enter description") }

        const formData = {
            ...state,
            title, url, sortOrder, description, videoStatus,
            dateModfified: serverTimestamp(),
            modifiedBy: {
                fullName: user.name,
                email: user.email,
                uid: user.uid
            }
        }
        setIsProcessing(true)
        if (photo) {
            uploadPhoto(formData)
        } else {
            updateDocument(formData)
        }

    }

    const uploadPhoto = (formData) => {

        const ext = photo.name.split('.').pop()
        const pathwithFileName = `${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, photo);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
            (error) => {
                console.error(error)
                window.toastify("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let sizeInKB = Math.round(photo.size / 1024);
                    let obj = { url: downloadURL, size: sizeInKB, fileName: pathwithFileName }
                    let data = { ...formData, photo: obj }
                    updateDocument(data)
                });
            }
        )
    }

    const updateDocument = async (formData) => {

        const docRef = doc(firestore, "videos", formData.id)
        try {
            await setDoc(docRef, formData)
            navigate("/dashboard/website-settings/videos")
            message.success("A new video has been successfullfy Updated")
        } catch (error) {
            console.log(error)
            message.error("Something went wrong. Please try again")
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Edit video</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} className='mb-4' >
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Edit Video</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Title" required>
                                        <Input placeholder='Title' name='title' value={state?.title} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="URL" required>
                                        <Input placeholder='url' name='url' value={state?.url} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={state.videoStatus}
                                            onChange={(val) => handleVideoStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" value={state.description} name="description" maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Thumbnail (1280 * 720) px" className='mb-0 text-center'>
                                <>
                                    {photo
                                        ? <img src={URL.createObjectURL(photo)} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                        : <img src={state?.photo?.url} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                    }

                                    <div {...getRootPhotoProps({ className: 'dropzone p-1 mt-2' })}>
                                        {/* <InboxOutlined /> */}
                                        <input name='photo' {...getInputPhotoProps()} />
                                        <p className='mb-0'>Click or drag file</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleUpdate}>
                            {!isProcessing
                                ? "Update"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
