import React from 'react'
import ForgotPassword from './ForgotPassword'

export default function index() {
    return (
        <>
            <ForgotPassword />
        </>
    )
}
