import React, { useState, useCallback, useEffect } from 'react'
import { firestore } from 'config/firebase';
import { collection, doc, getDoc, getDocs, query, runTransaction, serverTimestamp, setDoc, where } from 'firebase/firestore';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Subscribe() {

    const [email, setEmail] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [subcribers, setSubcribers] = useState({});


    const readSubcribers = useCallback(async () => {
        const docRef = doc(firestore, "transactions", "subcribe");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setSubcribers(data)
        } else {
            window.notify("Something went wrong", "error")
        }
    }, [])

    useEffect(() => {
        readSubcribers()
    }, [readSubcribers])

    const handleSubmit = () => {

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regex.test(email)) { return window.notify("Invalid email format", "error") }

        const formData = {
            email,
            id: window.getRandomId(),
            dateCreated: serverTimestamp(),
            isunRead: false,
            status: "active",
        }

        setIsProcessing(true)
        checkSubcriber(formData)
    }

    const checkSubcriber = async (data) => {

        const q = query(collection(firestore, "subscribers"), where("email", "==", data.email), where("status", "==", "active"));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
            window.notify("This email is already subscribe", "error")
            setIsProcessing(false)
            return
        } else {
            getSubcribeNumber(data)
        }
    }

    const getSubcribeNumber = async (formData) => {

        setIsProcessing(true)
        const docRef = doc(firestore, "transactions", "subcribe");
        try {
            await runTransaction(firestore, async (transaction) => {
                const sfDoc = await transaction.get(docRef);

                const newPopulation = sfDoc.data().subcribe + 1;
                transaction.update(docRef, { subcribe: newPopulation });
                createDocuments(formData)

            });
        } catch (e) {
            console.log(e)
            setIsProcessing(false)
            window.notify("Something went wrong", "error")
        }
    }

    const createDocuments = async (data) => {

        const docRef = doc(firestore, "subscribers", data.id)
        try {
            await setDoc(docRef, data)
            setEmail("")
            setIsProcessing(false)
            window.notify("Thank you for subscribing", "success")
        } catch (error) {
            console.log(error)
            window.notify("Something went wrong. Please try again", "error")
            setIsProcessing(false)
        }
        setIsProcessing(false)
    }

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='bg-white'>
            <div className='py-5'>
                <div className="py-5 container">
                    <div className='d-flex justify-content-center' data-aos="zoom-in">
                        <div className='text-center'>
                            <h2 >Subscribe for Newsletter</h2>
                            <p className='fs-5'>{subcribers?.subcribe} People subscribe </p>
                            <div className='d-flex'>
                                <input type="email" name='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Enter your email' className='form-control me-3 p-2' />
                                <button className='btn btn-primary text-white ' disabled={isProcessing} onClick={handleSubmit} style={{ width: "8rem", }}>
                                    {!isProcessing ? "Subscribe" : (<span className='spinner-border spinner-border-sm'></span>)}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
