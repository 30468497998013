import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Input, Popconfirm, Row, Space, Table, Tag, Tooltip, message } from 'antd'
import { collection, doc, getDoc, getDocs, limit, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { firestore } from 'config/firebase'
import { EyeOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { FiSend } from 'react-icons/fi';
import emailjs from '@emailjs/browser';
const { Search } = Input;

export default function Hero() {

    const [users, setUsers] = useState({});
    const [subcribers, setSubcribers] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [documents, setDocuments] = useState([])
    const [filteredDocuments, setFilteredDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')

    const handleSearch = value => {
        setSearchText(value);
    };

    const readDocuments = useCallback(async () => {
        const docRef = doc(firestore, "transactions", "users");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setUsers(data)
        } else {
            window.notify("Something went wrong", "error")
        }
    }, [])

    useEffect(() => {
        readDocuments()
    }, [readDocuments])

    const readSubcribers = useCallback(async () => {
        const docRef = doc(firestore, "transactions", "subcribe");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setSubcribers(data)
        } else {
            window.notify("Something went wrong", "error")
        }
    }, [])

    useEffect(() => {
        readSubcribers()
    }, [readSubcribers])

    const readDocs = useCallback(async () => {

        const collectionRef = collection(firestore, "subscribers");
        const statusActive = where("status", "==", "active");
        const q = query(collectionRef, statusActive, limit(pageSize));

        setIsLoading(true);
        const querySnapshot = await getDocs(q);
        const array = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const dateCreated = doc.data().dateCreated;
            let date = null;
            if (dateCreated && dateCreated.seconds) {
                date = new Date(dateCreated.seconds * 1000 + dateCreated.nanoseconds / 1000000);
                data.date = date.toLocaleString();
            }
            data.key = doc.id;
            array.push(data);
        });
        array.sort((a, b) => b.dateCreated.seconds - a.dateCreated.seconds);
        setDocuments(array);
        setFilteredDocuments(array);
        setIsLoading(false);

    }, [pageSize]);

    useEffect(() => {
        readDocs();
    }, [readDocs]);

    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setFilteredDocuments(documents.slice(start, end));
    }, [currentPage, pageSize, documents]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleReadDoc = async (id) => {
        const docRef = doc(firestore, "subscribers", id);
        try {
            await updateDoc(docRef, { isunRead: true })
        } catch (error) {
            console.log(error)
            window.notify("Something went wrong.", "error")
        }
    }

    useEffect(() => {
        const filteredData = documents?.filter((item) =>
            item.email.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredDocuments(filteredData);
    }, [documents, searchText]);


    const SingleDocDelete = async (item) => {

        item.status = "delete"
        const docRef = doc(firestore, "subscribers", item.id)
        try {
            await setDoc(docRef, item, { merge: true })
            const activeDoc = documents.filter(docs => docs.id !== item.id)
            setDocuments(activeDoc)
            message.success("Subscriber successfully deleted")
        } catch (error) {
            message.error("Someting went wrong. Please try again")
        }

    }

    // const sendEmail = (e, record) => {
    //     e.preventDefault()

    //     let data = { ...record }
    //     delete data.id
    //     setIsProcessing(true)
    //     emailjs.send(process.env.REACT_APP_SERVICE_EMAIL_KEY, process.env.REACT_APP_TEMPLATE_EMAIL_ID, data, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
    //         .then((result) => {
    //             message.success("Email has been successfullfy sent")
    //         }, (error) => {
    //             console.error("error =>", error.text);
    //             message.error("Something went wrong. Please try again")
    //         });
    //     setIsProcessing(false)

    // };

    const columns = [

        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (tags) => (<> <Tag color="green" className='text-capitalize' >  {tags} </Tag> </>)
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <Tooltip title="Send Email">
                        <Button size="small" type='primary' disabled={isProcessing} onClick={(e) => sendEmail(e, record)}><FiSend /></Button>
                    </Tooltip> */}
                    <Tooltip title="Read Subscriber">
                        <Button size="small" type='primary' disabled={record.isunRead} onClick={() => handleReadDoc(record.id)}><EyeOutlined /></Button>
                    </Tooltip>
                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => SingleDocDelete(record)}
                        okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Button danger size="small" type='primary'><DeleteOutlined /></Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const rowClassName = (record, index) => {
        if (!record.isunRead) {
            return "rowColor"
        }
    };

    return (
        <div className='content-min-vh dashboard'>
            <div className='mb-5'>
                <h5>Welcome Admin!</h5>
            </div>
            <div>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} >
                        <Card title="Total User" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>{users.users}</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} >
                        <Card title="Total Blogs" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>0</p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Card title="Total Subcriber" className='mb-3 cardBorder'>
                            <p className='fw-bold text-center fs-5'>{subcribers.subcribe}</p>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div>
                <Row>
                    <Col xs={24} >
                        <div className="card bg-white p-4">
                            <div className='py-4'>
                                <div className='d-flex justify-content-between '>
                                    <p className='fw-bold pt-1 me-3'>Recently Subcribers</p>
                                    <div>
                                        <Search placeholder='Search'
                                            onChange={e => handleSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Table loading={isLoading}
                                dataSource={filteredDocuments}
                                columns={columns}
                                pagination={{ current: currentPage, pageSize: pageSize, total: documents.length, showSizeChanger: true, }}
                                rowClassName={rowClassName}
                                onChange={handleTableChange}
                                // expandable={{
                                //     expandedRowRender: (record) => (
                                //         <div className='px-5'>
                                //             <h6>{record.subject} </h6>
                                //             <p>{record.message} </p>
                                //         </div>
                                //     ),
                                //     rowExpandable: (record) => record.name !== 'Not Expandable',
                                //     onExpand: (expanded, record) => {
                                //         if (record.isunRead === true) {
                                //             return record;
                                //         } else {
                                //             return handleReadDoc(record.id);
                                //         }
                                //     }
                                // }}
                                scroll={{ x: true }}
                                bordered
                            />
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}
