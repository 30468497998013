import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import { Pagination } from 'swiper/modules';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { firestore } from 'config/firebase';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function MainHeader() {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    const readCourses = useCallback(async () => {

        setIsLoading(true)
        const collectionRef = query(collection(firestore, "images"), where("status", "==", "active"), where("sliderStatus", "==", "active"), orderBy("sortOrder", "asc"))
        const querySnapshot = await getDocs(collectionRef);

        let array = [];
        querySnapshot.forEach((docs) => {
            let data = docs.data()
            data.key = data.id
            array.push(data)
        })
        setIsLoading(false)
        setDocuments(array)

    }, [])

    useEffect(() => {
        readCourses()
    }, [readCourses])

    return (
        <div className="container-fluid pt-3">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    documents?.map((item) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <img src={item.url} className='img-fluid rounded-2 w-100' alt="img" />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}
