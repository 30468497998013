import React from 'react'
import { Link } from 'react-router-dom'
export default function NotFound() {
    return (
        <div className='container p-5 flex-center'>
            <div className="404 text-center">
                <h1 className='fw-bold text-primary' style={{ fontSize: 100 }}>404!</h1>
                <h3 className='text-danger fs-2 fw-bold text-uppercase'>Sorry Page Was Not Found</h3>
                <p>The page you are looking is not available or has been removed. <br /> Try going to Home Page by using the button below.</p>
                <button className='px-4 py-2 fs-6 fw-bold text-uppercase border-0 rounded-1 bg-primary'><Link to='/' className='text-decoration-none text-white'>Go to Home Page</Link></button>
            </div>
        </div>
    )
}
