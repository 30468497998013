import { useAuthGlobalContext } from 'context/AuthContext';
import ScreenLoader from 'pages/Frontend/ScreenLoader';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Routers from './pages/Routers';
import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './App.scss';
import { useEffect } from 'react';

function App() {

  const { isLoading } = useAuthGlobalContext();
  const readMainAd = async () => {

    const collectionRef = query(collection(firestore, "mainads"), where("isNewAds", "==", true))
    const querySnapshot = await getDocs(collectionRef);

    querySnapshot.forEach((docs) => {
      let data = docs.data()
      sessionStorage.setItem("mainAd", JSON.stringify(data));
    })
  }

  useEffect(() => {
    readMainAd()
  }, [])

  if (isLoading) {
    return <ScreenLoader />
  }

  return (
    <>
      <Routers />
      <ToastContainer />
    </>
  );
}

export default App;
