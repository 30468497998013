import React from 'react'
import Subscribe from './Subscribe'
import Videos from './Videos'
import Courses from './Courses'
import MainHeader from './MainHeader'

export default function index() {
    return (
        <>
            <MainHeader />
            <Courses />
            <Videos />
            <Subscribe />
        </>
    )
}
