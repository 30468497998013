import React, { useState, useEffect } from 'react'
import { auth } from 'config/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function ForgotPassword() {

    const [state, setState] = useState({ email: "", });
    const handleChange = (e) => {
        e.preventDefault();
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        sendPasswordResetEmail(auth, state.email)
            .then(() => {
                setState({ email: "", })
                window.notify("Reset email sent", "success")
            })
            .catch((error) => {
                console.error(error)
            });
    }

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <nav>
                <div className='container py-3 navbar'>
                    <Link to="/"> <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" /> </Link>
                </div>
            </nav>
            <div className="login-form">
                <div className="container min-vh-100 d-flex justify-content-center flex-column">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-12 col-md-5">
                            <div className="card rounded-4 border-0 shadow-lg w-100 " data-aos="zoom-in">
                                <div className="row">
                                    <div className="col p-3">
                                        <div className="card-body">
                                            <div className="col">
                                                <h3>Forgot Password</h3>
                                                <p className="text-muted">Let Us Help You</p>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter your email"
                                                        aria-label="First name"
                                                        name="email"
                                                        value={state.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-center mb-2 loginDiv'>
                                                <button className='btn btn-primary text-white w-100 rounded-2 border-0 loginbutton '
                                                    onClick={handleSubmit}
                                                >Send reset link</button>
                                            </div>
                                            <div className='py-2'>
                                                <Link to="/auth/login" className='text-decoration-none'>Try login again</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
