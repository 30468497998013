import React, { useState } from 'react'
import { Col, Form, Input, message, Row, Select, Typography } from 'antd'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from 'config/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography;
const initialState = { url: "", sortOrder: 1 }

export default function AddCarousel() {

    const { user } = useAuthGlobalContext()
    const [state, setState] = useState(initialState);
    const [isProcessing, setIsProcessing] = useState(false);
    let navigate = useNavigate();

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handlesliderStatus = (val) => {
        setState(s => ({ ...s, sliderStatus: val }))
    }

    const handleSubmit = () => {
        let { url, sortOrder, sliderStatus } = state;

        url = url.trim();
        sortOrder = Number(sortOrder);

        if (!url) { return message.error("Please enter image url") }
        if (!sliderStatus) { return message.error("Please select status") }
        if (!sortOrder) { return message.error("Please enter sort order") }

        const formData = {
            url, sortOrder, status: "active", sliderStatus,
            id: window.getRandomId(),
            dateCreated: serverTimestamp(),
            createdBy: {
                fullName: user.name,
                email: user.email,
                uid: user.uid
            }
        }
        setIsProcessing(true)
        createDocument(formData)

    }

    const createDocument = async (formData) => {

        const docRef = doc(firestore, "images", formData.id)
        try {
            await setDoc(docRef, formData)
            navigate("/dashboard/website-settings/carousel")
            message.success("A new slider image has been successfullfy added")
        } catch (error) {
            console.log(error)
            message.error("Something went wrong. Please try again")
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Slider</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} >
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Add New Slider Image</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>

                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="URL" required>
                                        <Input placeholder='Url' name='url' onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            onChange={(val) => handlesliderStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleSubmit}>
                            {!isProcessing
                                ? "Add"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
