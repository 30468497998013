import React, { useCallback, useState, useEffect } from 'react'
import { Breadcrumb, Button, Col, Form, Input, message, Row, Select, Tooltip, Typography } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { firestore, storage } from 'config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { EditOutlined, CloseOutlined } from "@ant-design/icons"

const initialValue = { title: "", name: "", text: "", sortOrder: 1, description: "" }
const { Title } = Typography;

export default function EditBlog() {

    const { user } = useAuthGlobalContext();
    const [state, setState] = useState(initialValue);
    const [file, setFile] = useState(null);
    const [isSlugEditable, setIsSlugEditable] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    // Dropzone
    const { getRootProps: getRootPhotoProps, getInputProps: getInputPhotoProps } = useDropzone({
        accept: { 'image/*': [] }, multiple: false,
        onDrop: acceptedFiles => { setFile(acceptedFiles[0]) }
    });

    const handleChange = e => {
        const { name, value } = e.target
        setState(s => ({ ...s, [name]: value }))

        if (name === "title") {
            let slug = window.getSlug(value)
            setState(s => ({ ...s, slug }))
        }
    }

    const handleCkEditor = (event, editor) => {

        const data = editor.getData();
        setState(s => ({ ...s, text: data }))

    }

    const editorConfig = {
        ckfinder: {
            uploadUrl: 'your-upload-url',
        },
    }


    const handleblogStatus = (val) => {
        setState(s => ({ ...s, blogStatus: val }))
    }

    const readBlogs = useCallback(async () => {

        const docRef = doc(firestore, "blogs", params.id)
        setIsProcessing(true)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            message.error("Blog not found")
        }
        setIsProcessing(false)

    }, [params.id])

    useEffect(() => {
        readBlogs()
    }, [readBlogs])

    const handleUpdate = () => {

        let { title, slug, name, blogStatus, sortOrder, description, text } = state;

        if (title.length < 1) { return message.error("Please enter title") }
        if (name.length < 1) { return message.error("Please enter author name") }
        if (!blogStatus) { return message.error("Please enter blog status") }
        if (!description) { return message.error("Please enter blog description") }
        if (!text) { return message.error("Please enter blog description text correctly") }

        title = title.trim();
        slug = slug.trim();
        name = name.trim();
        description = description.trim()
        sortOrder = Number(sortOrder);

        let formData = {
            title, slug, name, blogStatus, description, text, sortOrder,
            dateModified: serverTimestamp(),
            status: "active",
        }
        formData.modifiedBy = {
            fullName: user.name,
            email: user.email,
            uid: user.uid
        }
        setIsProcessing(true)
        if (file) {
            uploadFile(formData)
        } else {
            updateDocument(formData)
        }

    }


    const uploadFile = (formData) => {

        const ext = file.name.split('.').pop()
        const pathwithFileName = `${formData.id}/images/photo.${ext}`

        const fileRef = ref(storage, pathwithFileName);

        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on("state_changed", (snapshot) => {
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
            (error) => {
                console.error(error)
                console.log('error', error)
                message.error("Something went wrong while uploading photo.", "error")
                setIsProcessing(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let photo = { url: downloadURL, size: file.size }
                    updateDocument({ ...formData, photo })
                });
            }
        )
    }


    const updateDocument = async (formData) => {

        const docRef = doc(firestore, "blogs", state.id)
        try {
            await updateDoc(docRef, formData)
            setFile(null)
            setIsProcessing(false)
            navigate("/dashboard/blogs")
            message.success("Blog has been successfullfy updated")
        } catch (error) {
            console.log(error)
            message.error("Something went wrong. Please try again")
        }
    }

    return (
        <div className='content-min-vh'>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/dashboard" className='text-decoration-none'>Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Blog</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='py-4'>
                <h4>Edit Blog</h4>
                {/* <div dangerouslySetInnerHTML={{ __html: editorData }}></div> */}
            </div>
            <Row gutter={16} >
                <Col xs={24} xl={16} className="mb-3" >
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Edit Blog</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Title" required>
                                        <Input placeholder='Blog Title' name='title' value={state.title} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Slug" required>
                                        <Input.Group compact>
                                            <Input placeholder="Slug" name="slug" disabled={isSlugEditable} value={state.slug} onChange={handleChange} style={{ width: "calc(100% - 32px)" }} />
                                            <Tooltip title={isSlugEditable ? "Edit" : "Cancel Edit"}>
                                                <Button onClick={() => { setIsSlugEditable(!isSlugEditable) }} icon={isSlugEditable ? <EditOutlined /> : <CloseOutlined />} />
                                            </Tooltip>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Auther name" required>
                                        <Input placeholder="Auther name" name='name' value={state.name} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={state.blogStatus}
                                            onChange={(val) => handleblogStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={24}>
                                    <Form.Item label="Description">
                                        <Input.TextArea rows={5} style={{ resize: "none" }} placeholder="Description" name="description" value={state.description} maxLength={200} showCount onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col lg={24} xs={24}>
                                    <div><CKEditor className="ck-editor__editable" data={state.text} editor={ClassicEditor} config={editorConfig} onChange={handleCkEditor} /></div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <Form layout="vertical">
                            <Form.Item label="Picture (1000*1000) px" className='mb-0 text-center'>
                                <>
                                    {file
                                        ? <img src={URL.createObjectURL(file)} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                        : <img src={state.photo?.url} alt="Thumbnail" className='img-fluid rounded-1' style={{ maxWidth: "50%" }} />
                                    }

                                    <div {...getRootPhotoProps({ className: 'dropzone p-1 mt-2' })}>
                                        {/* <InboxOutlined /> */}
                                        <input name='photo' {...getInputPhotoProps()} />
                                        <p className='mb-0'>Click or drag file</p>
                                    </div>
                                </>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleUpdate}>
                            {!isProcessing
                                ? "Update"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
