import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Input, message, Row, Select, Typography } from 'antd'
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from 'config/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Title } = Typography;

export default function EditCarousel() {

    const { user } = useAuthGlobalContext()
    const [state, setState] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    let params = useParams();
    let navigate = useNavigate();

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleVideoStatus = (val) => {
        setState(s => ({ ...s, sliderStatus: val }))
    }

    const readVideo = useCallback(async () => {

        const docRef = doc(firestore, "images", params.id)
        setIsProcessing(true)
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            setState(data)
        } else {
            window.toastify("Slider image not found", "error")
        }
        setIsProcessing(false)

    }, [params.id])

    useEffect(() => {
        readVideo()
    }, [readVideo])

    const handleUpdate = () => {
        let { url, sortOrder, sliderStatus } = state;

        url = url.trim();
        sortOrder = Number(sortOrder);

        if (!url) { return message.error("Please enter video url") }
        if (!sliderStatus) { return message.error("Please select slider image status") }
        if (!sortOrder) { return message.error("Please enter sort order") }

        const formData = {
            ...state,
            url, sortOrder, sliderStatus,
            dateModfified: serverTimestamp(),
            modifiedBy: {
                fullName: user.name,
                email: user.email,
                uid: user.uid
            }
        }
        setIsProcessing(true)
        updateDocument(formData)

    }

    const updateDocument = async (formData) => {

        const docRef = doc(firestore, "images", formData.id)
        try {
            await setDoc(docRef, formData)
            navigate("/dashboard/website-settings/carousel")
            message.success("A new slider image has been successfullfy Updated")
        } catch (error) {
            console.log(error)
            message.error("Something went wrong. Please try again")
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Edit Slider Image</Title>
                </Col>
            </Row>
            <Row gutter={16} >
                <Col xs={24} xl={16} className='mb-4' >
                    <div className="card p-4">
                        <Row className='mb-4'>
                            <Col>
                                <Title level={4} className="mb-0">Edit Slider Image</Title>
                            </Col>
                        </Row>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="URL" required>
                                        <Input placeholder='url' name='url' value={state?.url} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Sort Order" required>
                                        <Input type='number' min={1} placeholder="Sort Order" name="sortOrder" value={state.sortOrder} onChange={handleChange} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <Form.Item label="Status" required>
                                        <Select
                                            showSearch
                                            className='w-100%'
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={state.sliderStatus}
                                            onChange={(val) => handleVideoStatus(val)}
                                            options={[
                                                { value: 'active', label: 'Active', },
                                                { value: 'inactive', label: 'In Active', },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col xs={24} xl={8}>
                    <div className="card p-2 mb-3">
                        <button className='btn btn-primary btn-sm text-white' disabled={isProcessing} onClick={handleUpdate}>
                            {!isProcessing
                                ? "Update"
                                : <span className='spinner-grow spinner-grow-sm'></span>
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    )
}
