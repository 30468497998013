import React, { useCallback, useState, useEffect } from 'react';
import { firestore } from 'config/firebase';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Breadcrumb, Button, Col, Divider, Dropdown, Row, Space, Table, Tag, Typography, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { MoreOutlined } from "@ant-design/icons"

const { Title, Text } = Typography;

export default function AllVideo() {

    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate()

    const readVideos = useCallback(async () => {
        setIsLoading(true)
        const collectionRef = query(collection(firestore, "videos"), where("status", "==", "active"))
        const querySnapshot = await getDocs(collectionRef);

        let array = [];
        querySnapshot.forEach((docs) => {
            let data = docs.data()
            data.key = data.id
            array.push(data)
        })
        setDocuments(array)
        setIsLoading(false)

    }, [])

    useEffect(() => {
        readVideos()
    }, [readVideos])

    const handleDelete = async (id) => {

        setIsLoading(true)
        try {
            await updateDoc(doc(firestore, "videos", id), { status: "deleted" })
            let activeVideoDocs = documents.filter(item => item.id !== id);
            setDocuments(activeVideoDocs)
            setIsLoading(false)
            message.success("Video successfully deleted")
        } catch (error) {
            message.error("Something went wrong. Please try again.")
        }
        setIsLoading(false)

    }

    const columns = [
        {
            title: 'Image',
            render: (item, row) => {
                return (
                    <div>
                        <img src={row.photo?.url} alt="logo" className='rounded-2' style={{ maxWidth: "200px", height: "80px" }} />
                    </div>
                )
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
        {
            title: 'Size',
            render: (_, row) => { return <p>{row.photo?.size}KB</p> }
        },
        {
            title: 'File Name',
            render: (_, row) => { return <p>{row.photo.fileName}</p> }
        },
        {
            title: 'Sort Order',
            dataIndex: 'sortOrder',
        },
        {
            title: 'Status',
            render: (_, tags) => { return <Tag className='text-capitalize' color={tags?.videoStatus === "active" ? "green" : "red"}>  {tags.videoStatus}  </Tag> }
        },
        {
            title: 'Action',
            render: (_, row) => {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: [
                                    { label: "Edit", onClick: () => { navigate(row.id) } },
                                    { label: "Delete", onClick: () => { handleDelete(row.id) } },
                                ]
                            }}
                            trigger={['click']}
                        >
                            <MoreOutlined className='text-primary' />
                        </Dropdown>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">Videos</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Videos</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={24}>
                    <div className="card p-4">
                        <Row>
                            <Col span={24} className="d-flex justify-content-end align-items-center">
                                <Space size="small">
                                    <Button type='primary' onClick={() => navigate("add")}>Add</Button>
                                </Space>
                            </Col>
                        </Row>

                        <Divider />

                        <Table columns={columns} dataSource={documents} scroll={{ x: true }} bordered loading={isLoading}
                            expandable={{
                                expandedRowRender: (row) => <div className='mx-5'>
                                    <Title level={5}>Description</Title>
                                    <Text>{row.description}</Text>
                                </div>,
                            }}
                        />
                    </div>
                </Col>
            </Row>

        </>
    )
}
