import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainAd from './MainAd/MainAd'
import AddVideo from './Videos/Add'
import AllVideo from './Videos/All'
import EditVideo from './Videos/Edit'
import AddCarousel from './Carousel/Add'
import AllCarousel from './Carousel/All'
import EditCarousel from './Carousel/Edit'

export default function index() {
    return (
        <Routes>
            <Route path='mainAd' element={<MainAd />} />
            <Route path='carousel/add' element={<AddCarousel />} />
            <Route path='carousel' element={<AllCarousel />} />
            <Route path='carousel/:id' element={<EditCarousel />} />
            <Route path='videos/add' element={<AddVideo />} />
            <Route path='videos' element={<AllVideo />} />
            <Route path='videos/:id' element={<EditVideo />} />
        </Routes>
    )
}
