import { toast } from "react-toastify"
import logo from "assets/logo1.png";
window.logoURL = logo;
window.appName = "Drama Series"

window.getRandomId = () => {
    return Math.random().toString(36).slice(2)
}

window.getSlug = (name) => {
    let slug = name?.replace(/[^a-zA-Z0-9]/g, ' ');
    slug = name.replace(/  +/g, ' ');
    slug = name.replace(/\s/g, '-').toLowerCase().replace(/[^\w-]+/g, '-');
    return slug;
}

window.notify = (msg, type) => {
    let option = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    switch (type) {
        case "success":
            toast.success(msg, option)
            break;
        case "error":
            toast.error(msg, option)
            break;
        case "warning":
            toast.warning(msg, option)
            break;
        default:
            toast(msg, option)
    }
}