import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from 'config/firebase';

const initialState = { newPassword: "", confirmPassword: "" };

export default function RestPassword() {

    const [state, setState] = useState(initialState);
    const [oobCode, setOobCode] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const readData = async () => {

        let query = new URLSearchParams(window.location.search)
        let oobCode = query.get("oobCode")
        setOobCode(oobCode)

    }

    useEffect(() => {
        readData()
    }, [])

    const handleResetPassword = () => {

        const { newPassword, confirmPassword } = state;

        if (newPassword.length < 8) {
            return window.notify("Password should be minimum 8 chars.", "error")
        }
        if (confirmPassword !== newPassword) {
            return window.notify("Password dosen't match", "error")
        }

        setIsProcessing(true)
        confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => {
                window.notify("Your password has been successfully updated. Please login to continue.", "success")
                setTimeout(() => {
                    navigate("/auth/login");
                }, 1000);
            })
            .catch((error) => {
                console.error(error)
                window.notify("Something went wrong. Please contact support team.", "error")
            }).finally(() => {
                setIsProcessing(false)
            })
    }


    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <nav>
                <div className='container py-3 navbar'>
                    <Link to="/"> <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" /> </Link>
                </div>
            </nav>
            <div className="login-form">
                <div className="container min-vh-100 d-flex justify-content-center flex-column">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-12 col-md-5">
                            <div className="card rounded-4 border-0 shadow-lg w-100 " data-aos="zoom-in">
                                <div className="row">
                                    <div className="col p-3">
                                        <div className="card-body">
                                            <div className="col">
                                                <h3 className='mb-4'>Reset Password</h3>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="New password"
                                                        name="newPassword"
                                                        value={state.newPassword}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                        name="confirmPassword"
                                                        value={state.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-center mb-2 loginDiv'>
                                                <button className='btn btn-primary text-white w-100 rounded-2 border-0 loginbutton '
                                                    onClick={handleResetPassword}
                                                >
                                                    {
                                                        !isProcessing ? "Rest password" : (<span className="spinner-border spinner-border-sm " role="status"></span>)
                                                    }

                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
