import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ForgotPassword from './ForgotPassword'
import Login from './Login/Login'
import Register from './Register/Register'
import RestPassword from './RestPassword/RestPassword'

export default function index() {
    return (
        <Routes>
            <Route path='login' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='reset-password' element={<RestPassword />} />
        </Routes>
    )
}
