import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';

export default function Login() {

    const { dispatch } = useAuthGlobalContext();
    const [state, setState] = useState({ email: "", password: "", });
    const [isProcessing, setisProcessing] = useState(false);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const { email, password } = state;

        if (email === "") { return window.notify("please enter your email", "error") }
        if (password === "") { return window.notify("please enter your password", "error") }

        setisProcessing(true)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                setState({ email: "", password: "", })
                readUserData(user)
            })
            .catch((error) => {
                console.log('error', error)
                if (error.code === 'auth/invalid-email') { window.notify('Invalid email', "error") }
                if (error.code === 'auth/wrong-password') { window.notify('Please check the Password', "error") }
                if (error.code === 'auth/user-not-found') { window.notify('This email have been not register', "error") }
                setisProcessing(false)
            });

        const readUserData = async (user) => {

            const docRef = doc(firestore, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                let userData = docSnap.data()
                const isSuperAdmin = userData.roles.includes("superAdmin")
                // console.log("userData =>", userData)
                dispatch({ type: "LOGIN", payload: { user: userData, isSuperAdmin } })
                window.notify("Login successful", "success")
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
            setisProcessing(false)
        }
    }

    // const provider = new GoogleAuthProvider();
    // const handlerGoogle = (e) => {
    //     e.preventDefault();
    //     signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             GoogleAuthProvider.credentialFromResult(result);
    //             const user = result.user;
    //             window.notify("Successfullfy login", "success")
    //             await setDoc(doc(firestore, "users", user.uid), {
    //                 photoURL: user.photoURL,
    //                 fullName: user.displayName,
    //                 uid: user.uid,
    //                 role: "user",
    //                 dateCreated: serverTimestamp(),

    //             }, { merge: true }).then(() => {
    //                 console.log("document Create")
    //             }).catch((error) => {
    //                 console.log(error)
    //             })

    //             console.log(user)
    //             // ...
    //             dispatch({ type: "LOGIN" })

    //         }).catch((error) => {
    //             // Handle Errors here.
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             // The email of the user's account used.
    //             const email = error.customData.email;
    //             // The AuthCredential type that was used.
    //             const credential = GoogleAuthProvider.credentialFromError(error);
    //             console.log(error)
    //             // ...
    //         });
    // }

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <nav >
                <div className='container py-3 navbar'>
                    <Link to="/"> <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" /> </Link>
                </div>
            </nav>
            <div className="login-form">
                <div className="container min-vh-100 d-flex justify-content-center flex-column">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-12 col-md-5">
                            <div className="card rounded-4 border-0 shadow-lg w-100" data-aos="zoom-in">
                                <div className="row">
                                    <div className="col p-3">
                                        <div className="card-body">
                                            <form>
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className='fw-bold'>Drama Series</h5>
                                                        <p className="text-muted">Need an account? <Link to="/auth/register" className='text-primary fw-bold text-decoration-none'>Register</Link></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4 className='text-uppercase'>login</h4>
                                                    </div>
                                                </div>
                                                <div className="py-3">
                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            <input type="email" className="form-control" placeholder="Enter your email" aria-label="First name" name='email'
                                                                value={state.email}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col">
                                                            <input type="password" className="form-control" placeholder="Enter your password" aria-label="First name" name='password'
                                                                value={state.password}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-end mb-3 underInput' >
                                                    <Link to="/auth/forgot-password" className='fw-bold text-decoration-none text-primary forgot' >Forgot Password ?</Link>
                                                </div>
                                                <div className='text-center mb-4 loginDiv'>
                                                    <button className='btn btn-primary w-100 rounded-2 border-0 loginbutton text-white  '
                                                        onClick={handleSubmit}
                                                        disabled={isProcessing}
                                                    >
                                                        {!isProcessing ? ("Login") : (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                                                    </button>
                                                </div>

                                                <div className='login-or'>
                                                    <span className="or-line"></span>
                                                    <span className="span-or">Or</span>
                                                </div>

                                                {/* <div className='d-flex justify-content-center'>
                                                    <button className='btn btn-sm border-0  text-white ' style={{ backgroundColor: "#dd4b39" }} onClick={handlerGoogle}  ><FaGoogle className='text-white mx-2' /></button>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
