import React from 'react'
import Footer from './Footer'

export default function index() {
    return (
        <>
            <Footer />
        </>
    )
}
