import React from 'react';
import { useAuthGlobalContext } from 'context/AuthContext';
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
export default function Navbar() {

    const { handlLogOut, isAuthenticated, isSuperAdmin } = useAuthGlobalContext();

    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-white shadow py-3 ">
            <div className="container">
                <Link to="/"> <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" style={{ width: "200px" }} /> </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" style={{ border: "none" }} >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel"  >
                    <div className="offcanvas-header ">
                        <div className="offcanvas-title" id="offcanvasNavbarLabel">
                            <img src={window.logoURL} alt={`${window.appName} Logo`} className="navbar-brand p-0" style={{ width: "200px" }} />
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"  ></button>
                    </div>
                    <div className="offcanvas-body text-center navlink " >
                        <ul className="navbar-nav  mb-2 mb-lg-0  px-4 ms-auto ">
                            <li className="nav-item navitem" data-bs-toggle="offcanvas"  >
                                <Link to="/" className="nav-link fw-bold text-dark opacity-75"> Home </Link>
                            </li>
                            <li className="nav-item fw-bold navitem" data-bs-toggle="offcanvas"  >
                                <Scroll to="about" className="nav-link text-dark opacity-75 links" activeClass="active" spy={true} smooth={true} offset={-50} duration={500}  >
                                    About US
                                </Scroll>
                            </li>
                            {/* <li className="nav-item fw-bold navitem" >
                                <a href='#' className="nav-link text-dark opacity-75" >
                                    Blogs
                                </a>
                            </li> */}
                            <li className="nav-item fw-bold navitem" data-bs-toggle="offcanvas" >
                                {
                                    isSuperAdmin &&
                                    <Link to="/dashboard" className="nav-link text-dark opacity-75">
                                        Dashboard
                                    </Link>
                                }
                            </li>
                        </ul>

                        <div>
                            <li className="navitem" data-bs-toggle="offcanvas" style={{ listStyle: "none" }} >
                                {

                                    !isAuthenticated ?
                                        (
                                            <Link to="/auth/login" className="btn btn-primary text-white px-3"  >
                                                Login
                                            </Link>
                                        )
                                        :
                                        (
                                            <button className='btn btn-danger text-white px-3'
                                                onClick={handlLogOut}
                                            >Logout</button>
                                        )
                                }
                            </li>

                        </div>
                    </div>
                </div>
            </div>
        </nav >
    )
}