import { useState } from 'react';
import { Layout, Menu, Typography } from 'antd';
import Header from "Components/dashboard/Header"
import Routes from "./Routes";
import { items } from './SidbarItems';
import { useAuthGlobalContext } from 'context/AuthContext';

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;


export default function Index() {
    const { user } = useAuthGlobalContext()
    const [collapsed, setCollapsed] = useState(false);
    const siderWidth = { marginLeft: collapsed ? 80 : 250 }

    return (
        <>
            <Header />
            <Layout hasSider >
                <Sider breakpoint='lg' collapsible collapsed={collapsed} collapsedWidth="80px" width="240px" className='position-fixed vh-100 '
                    onCollapse={(collapsed) => {
                        setCollapsed(collapsed);
                    }}
                    style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}  >
                    <div className='py-3'>
                        <div className=" d-flex justify-content-center">
                            <div>
                                <img src={user.photo?.url} className="img-fluid rounded-2 mb-2" style={{ width: "80px", height: "80px" }} alt="admin" />
                                <Typography className='text-white text-center'>Admin</Typography>
                                <Title level={5} className='text-white text-center m-0'>{user.name}</Title>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
                    </div>
                </Sider>
                <Layout className="site-layout " >
                    <Content className='p-3 p-md-4 content-min-vh' style={{ ...siderWidth, transition: "all 0.2s" }}>
                        <Routes />
                    </Content>
                    <Footer className='text-center' style={siderWidth}>Ant Design ©2023 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        </>
    );
};