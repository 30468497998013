import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddBlog from './Blog/Add'
import AllBlogs from './Blog/All'
import Hero from './Hero/Hero'
import WebsiteSettings from './WebsiteSettings';
import Messages from './Messages/Messages'
import Settings from './Settings'
import EditBlog from './Blog/Edit'

export default function Index() {
    return (
        <div className="dashboard">
            <Routes>
                <Route path='/' element={<Hero />} />
                <Route path='blogs/add' element={<AddBlog />} />
                <Route path='blogs' element={<AllBlogs />} />
                <Route path='blogs/:id' element={<EditBlog />} />
                <Route path='settings/*' element={<Settings />} />
                <Route path='website-settings/*' element={<WebsiteSettings />} />
                <Route path='messages' element={<Messages />} />
            </Routes>
        </div>
    )
}
