import { firestore } from 'config/firebase';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useNavigate } from 'react-router-dom';

export default function Courses() {

    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    let navigate = useNavigate()
    // console.log('courses', courses)
    const readCourses = useCallback(async () => {

        setIsLoading(true)
        const collectionRef = query(collection(firestore, "blogs"), where("status", "==", "active"), where("blogStatus", "==", "active"), orderBy("sortOrder", "asc"), limit(10))
        const querySnapshot = await getDocs(collectionRef);

        let array = [];
        querySnapshot.forEach((docs) => {
            let data = docs.data()
            data.key = data.id
            array.push(data)
        })
        setIsLoading(false)
        setCourses(array)

    }, [])

    useEffect(() => {
        readCourses()
    }, [readCourses])

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='course py-5'>
            <div className="container py-5">
                <div className='text-center'>
                    <h1 className='fs-3 text-primary'>Recent Posts</h1>
                    <div className='underline'></div>
                    <p className='fs-3 '>Our articles</p>
                </div>
                <div className="row pt-5">
                    {
                        isLoading ? (
                            <div className='d-flex justify-content-center  mb-3'>
                                <span className='spinner-border spinner-lg text-primary'></span>
                            </div>
                        )
                            :
                            (
                                courses.map((item) => {
                                    return (
                                        <div key={item.id} className='col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4'>
                                            <div className="card border-0 shadow-lg h-100" data-aos="zoom-in">
                                                <img src={item.photo?.url} className="img-fluid " alt="img" />
                                                <div className="card-body">
                                                    <h5 className='text-truncate text-primary ' style={{ cursor: "pointer" }} onClick={() => navigate(`blog/${item.slug}`)}>
                                                        {item.title}
                                                    </h5>
                                                    <p className="course-summary customFont fs-4" style={{ cursor: "pointer", direction: 'rtl', }}>{item.description}</p>
                                                    <div className='d-flex justify-content-end'>
                                                        <button className='btn btn-primary text-white' onClick={() => navigate(`blog/${item.slug}`)}>Learn More</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })

                            )

                    }
                    <div className='text-center'>
                        <Link to="/blog" className='btn btn-primary text-white px-5 '>All Blogs</Link>
                    </div>

                </div>
            </div>

        </div >
    )
}
