import React, { useCallback, useState, useEffect } from 'react'
import { firestore } from 'config/firebase';
import { useAuthGlobalContext } from 'context/AuthContext';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Breadcrumb, Row, Col, Typography, Table, Divider, Space, Button, Tag, Dropdown, message, Modal } from 'antd'
import { MoreOutlined } from "@ant-design/icons"
import { Link, useNavigate } from 'react-router-dom';

const { Title } = Typography;

export default function AllBlogs() {

    const { isSuperAdmin } = useAuthGlobalContext()
    const [blogs, setBlogs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    let navigate = useNavigate()

    const readBlogs = useCallback(async () => {

        setIsProcessing(true)
        const collectionRef = collection(firestore, "blogs");
        const whereStatus = where("status", "==", "active");

        let q;

        if (isSuperAdmin) {
            q = query(collectionRef, whereStatus);
        }
        const querySnapshot = await getDocs(q);

        let array = []
        querySnapshot.forEach((docs) => {
            // doc.data() is never undefined for query doc snapshots
            let data = docs.data();
            let datecreated = docs.data().dateCreated;
            let date = new Date(datecreated.seconds * 1000 + datecreated.nanoseconds / 1000000);
            data.date = date.toLocaleString()
            data.key = data.id
            array.push(data)
        })
        setBlogs(array)
        setIsProcessing(false)

    }, [isSuperAdmin])

    useEffect(() => {
        readBlogs()
        // eslint-disable-next-line
    }, [readBlogs])


    const handleDelete = async (id) => {

        setIsProcessing(true)
        try {
            await updateDoc(doc(firestore, "blogs", id), { status: "deleted" })
            let activeBlogs = blogs.filter(item => item.id !== id);
            setBlogs(activeBlogs)
            setIsModalVisible(false)
            setIsProcessing(false)
            message.success("A blog successfully deleted")
        } catch (error) {
            message.error("Something went wrong. Please try again.")
        }
        setIsProcessing(false)

    }

    const columns = [
        {
            title: 'Image',
            render: (row, item) => (<div> <img className='avatar' src={item.photo?.url} alt="blog" /> </div>)
        },
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Author Name',
            sorter: (a, b) => a.name.length - b.name.length,
            dataIndex: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date.length - b.date.length,
        },
        {
            title: 'Status',
            render: (_, tags) => { return <Tag className='text-capitalize' color={tags?.blogStatus === "active" ? "green" : "red"}>  {tags.blogStatus}  </Tag> }
        },
        {
            title: 'Action',
            render: (_, row, index) => {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: [
                                    { label: "Edit", onClick: () => { navigate(row.id) } },
                                    { label: "Delete", onClick: () => { setIsModalVisible(index) } },
                                ]
                            }}
                            trigger={['click']}
                        >
                            <MoreOutlined className='text-primary' />
                        </Dropdown>
                        <Modal
                            title="Confirm Delete"
                            open={isModalVisible === index}
                            confirmLoading={isProcessing}
                            onOk={() => handleDelete(row.id)}
                            okButtonProps={{ style: { backgroundColor: '#ff4d4f', color: "white" } }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <p>Are you sure you want to delete this item?</p>
                        </Modal>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Title level={3} className="mb-0">All Blogs</Title>
                </Col>
            </Row>
            <div className='mb-4'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link className='text-decoration-none' to="/dashboard">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>All Blogs</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={24}>
                    <div className="card">
                        <div className="card-body">

                            <Row>
                                <Col span={24} className="d-flex justify-content-end align-items-center">
                                    <Space size="small">
                                        <Button type='primary' onClick={() => navigate("add")}>Add Blog</Button>
                                    </Space>
                                </Col>
                            </Row>

                            <Divider />

                            <Table
                                columns={columns}
                                dataSource={blogs}
                                loading={isProcessing}
                                bordered
                                scroll={{ x: true }}
                                expandable={{
                                    expandedRowRender: (record) => (<div className='px-5' dangerouslySetInnerHTML={{ __html: record.text }} ></div>),
                                    rowExpandable: (record) => record.name !== 'Not Expandable',
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
